import React, { useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useSettings from 'stores/settings'
import styled from '@emotion/styled'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'

const MobileHeader = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    height: 100%;
  }
`

const SwipeableDrawers = styled(SwipeableDrawer)`
  .MuiPaper-elevation.MuiDrawer-paper {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
`

const IconButtons = styled(IconButton)`
  :focus {
    outline: none;
  }
`

function MoHeader(props) {
  const { window } = props
  const [open, setOpen] = React.useState(false)
  const router = useRouter()
  const settings = useSettings()

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined
  useEffect(() => {
    settings.menuState = router.pathname
  }, [])

  const { currentMoMenu } = settings
  const Selected =
    'w-full text-gray-700 transition-all hover:bg-indigo-100 hover:text-black hover:font-bold px-10 py-6 border-b-4 text-base'
  const NotSelected =
    'w-full text-gray-700 transition-all hover:bg-indigo-100 hover:text-black hover:font-bold px-10 py-6 border-b-4 text-base'

  return (
    <MobileHeader>
      <div className='w-full flex'>
        <IconButtons
          size='large'
          aria-label='main_menu'
          onClick={toggleDrawer(true)}
          className='text-red-500'
        >
          <MenuIcon fontSize='large' />
        </IconButtons>
        <div className='w-full center -ml-14'>
          <img src='/images/logo_ai2.png' width={128} className='p-2' />
        </div>
      </div>
      <SwipeableDrawers
        container={container}
        anchor='bottom'
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          <Link href='/home'>
            <Typography
              variant='h6'
              className={currentMoMenu === 'home' ? Selected : NotSelected}
              onClick={() => setOpen(false)}
            >
              사업분야
            </Typography>
          </Link>
          <Link href='/about'>
            <Typography
              variant='h6'
              className={currentMoMenu === 'about' ? Selected : NotSelected}
              onClick={() => setOpen(false)}
            >
              서비스
            </Typography>
          </Link>
          <Link href='/solution'>
            <Typography
              variant='h6'
              className={currentMoMenu === 'solution' ? Selected : NotSelected}
              onClick={() => setOpen(false)}
            >
              기술 ・ 서비스
            </Typography>
          </Link>
          <Link href='/resource'>
            <Typography
              variant='h6'
              className={currentMoMenu === 'resource' ? Selected : NotSelected}
              onClick={() => setOpen(false)}
            >
              자료실
            </Typography>
          </Link>
          <Link href='/company'>
            <Typography
              variant='h6'
              className={currentMoMenu === 'company' ? Selected : NotSelected}
              onClick={() => setOpen(false)}
            >
              회사소개
            </Typography>
          </Link>
        </Box>
      </SwipeableDrawers>
    </MobileHeader>
  )
}

export default MoHeader
