import React, { useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import useSettings from 'stores/settings'
import styled from '@emotion/styled'
import MoHeader from './MoHeader'

// IMPORTANT test
const Header = observer(() => {
  const router = useRouter()
  const settings = useSettings()

  useEffect(() => {
    settings.menuState = router.pathname
  }, [])

  const { currentMenu } = settings
  const menuSelected =
    'border-red-500 text-black inline-flex items-center px-2 mx-8 pt-1 border-b-4 text-base font-bold'
  const menuNotSelected =
    'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-2 mx-8 pt-1 border-b-4 text-base font-medium'
  // const { photoURL } = settings;

  return (
    <nav className='w-full h-16 fixed top-0 left-0 bg-white shadow-xl z-50'>
      <PCHeader className='max-w-7xl mx-auto px-2 sm:px-4 lg:px-8'>
        <img src='/images/logo_ai2.png' width={128} className='p-2' />
        <div
          className='w-full flex justify-center'
          style={{ alignItems: 'center' }}
        >
          <Link href='/home'>
            <span
              className={
                currentMenu === 'home' ? menuSelected : menuNotSelected
              }
            >
              사업분야
            </span>
          </Link>
          <Link href='/solution'>
            <span
              className={
                currentMenu === 'solution' ? menuSelected : menuNotSelected
              }
            >
              기술 ・ 서비스
            </span>
          </Link>
          <Link href='/resource'>
            <span
              className={
                currentMenu === 'resource' ? menuSelected : menuNotSelected
              }
            >
              자료실
            </span>
          </Link>
          <Link href='/company'>
            <span
              className={
                currentMenu === 'company' ? menuSelected : menuNotSelected
              }
            >
              회사소개
            </span>
          </Link>
        </div>
      </PCHeader>
      <MoHeader />
    </nav>
  )
})

export default Header

const PCHeader = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
  @media (max-width: 1024px) {
    display: none !important;
  }
`
